import { StepConnector, StepLabel, Stepper  } from "@material-ui/core";
import styled from "styled-components";

export const CustomStepper = styled(Stepper)(() => ({
  backgroundColor: "transparent !important",
  marginTop: "15px",
}));

export const StepRow = styled(StepConnector)(({ completed, active, theme }) => ({
  backgroundColor: (completed || active) ? theme.colors.alertsuccess : theme.colors.grayLight,
  color:  (completed || active)  ? theme.colors.alertsuccess : theme.colors.grayLight,
  height: "3px",

  "& .MuiStepConnector-lineHorizontal": {
    borderTopStyle: "none"
  },
}));

export const CustomStepLabel = styled(StepLabel)(({ fail, theme }) =>({
  "& .MuiStepIcon-root": {
    backgroundColor: theme.colors.disabled,
    borderRadius: "50%",
    color: theme.colors.disabled,
    height: "20px",
    width: "20px",
  },
  "& .MuiStepIcon-text": {
    fontSize: "12px"
  },
  "& .MuiStepLabel-alternativeLabel": {
    fontSize: "12px"
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed ": {
    backgroundColor: theme.colors.white,
    color: theme.colors.alertsuccess,
  },
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    backgroundColor: fail
      ? theme.colors.error
      : theme.colors.warning,
    color: fail
      ? theme.colors.error
      : theme.colors.warning,
  },
}),
);